import "./ButtonAll.css";
import { Link } from "react-router-dom";
import { CircleArrow } from "../../assets/icons/icons";

function ButtonAll({ title, link }) {
  return (
    <Link className="button-link" to={link}>
      <p className="button-link__title">{title}</p>
      <div className="button-link__separator" />
      {CircleArrow({
        mainClassName: "button-link__icon",
        fillClassName: "button-link__icon-fill",
      })}
    </Link>
  );
}

export default ButtonAll;
