import { useRef, useEffect } from 'react'
import './CtgrMobPopup.css'

export default function CtgrMobPopup({ ctgrMobPopup, setCtgrMobPopup, children }) {

    return (
        <>
            <div className={ctgrMobPopup ?
                'ctgrMobPopup ctgrMobPopup_active' : 'ctgrMobPopup'} onClick={() => setCtgrMobPopup(false)}>
            </div>
            <div className={ctgrMobPopup ?
                'ctgrMobPopup__box ctgrMobPopup__box_active' : 'ctgrMobPopup__box'} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </>
    )
}
