import './Drop.css';
import { useState, useRef } from 'react';

import { motion } from "framer-motion";
import Checkbox from '../Checkbox/Checkbox'

import NL from '../SideMenu/NL/NL';
import { Link } from 'react-router-dom';
import { CATALOG_MAIN_LINK } from '../../assets/utils/constants';

function Drop({ isOpen, items, selectedCategory }) {
    const categoriesDropRef = useRef(null);

    return (
        <motion.div
            initial={{
                height: "0px",
                opacity: 0,
                visibility: "hidden",
            }}
            animate={{
                height: isOpen && selectedCategory ? "auto" : "0px",
                opacity: isOpen && selectedCategory ? 1 : 0,
                visibility: isOpen && selectedCategory ? "visible" : "hidden",
            }}
            transition={{ duration: 0.2 }}
            ref={categoriesDropRef}
            className={'drop__positioning'}
        >
           
                {items && items.length > 0 ?
                    items.map((item) => (

                        <Link
                            className="checkbox__label-text"
                            to={`/${CATALOG_MAIN_LINK}/${selectedCategory?.translit_name}/${item.translit_name}`}
                        >
                            {item.name}
                        </Link>

                    ))
                    :
                    null}

          

        </motion.div>
    );
};

export default Drop;