import { Link } from "react-router-dom";
import LinkTemplate from "../LinkTemplate/LinkTemplate";
import useWindowSize from "../../assets/hooks/useWindowSize";
import { LogoIcon, LogoIconMobile, CartIcon } from "../../assets/icons/icons";
import "./Header.css";
import { useContext } from "react";
import { CartContext } from "../../assets/contexts/cartContext";

const HEADER = [
  {
    // path: "/docs/about",
    path: "#",
    title: "О магазине",
  },
  {
    // path: "/docs/contact",
    path: "#",
    title: "Контакты",
  },
  {
    link: "tel:+78613720405",
    title: "+7 86137 2 04 05",
  },
];

function Header() {
  const { width } = useWindowSize();
  const cart_context = useContext(CartContext)
  const {
    cart,
    // handleAddToCart,
    // handleRemoveFromCart,
    // setCart,
  } = cart_context


  return (
    <div className="header">
      <Link className="header__logo-link" to="/">
        {width > 1000
          ? LogoIcon({
            mainClassName: "header__logo",
            fillClassName: "header__logo-fill",
          })
          : LogoIconMobile({
            mainClassName: "header__logo",
            fillClassName: "header__logo-fill",
          })}
      </Link>
      <div className="header__content">
        <div className="header__links">
          <LinkTemplate listData={HEADER} />
        </div>
        <Link className="header__button-cart" to="/cart">
          <p className="header__cart-text">Корзина</p>
          {CartIcon({
            mainClassName: "header__cart-logo",
            fillClassName: "header__cart-logo-fill",
          })}
          {cart && cart.length > 0 ? (
            <p className="header__cart-count">{cart.length}</p>
          ) : null}
        </Link>
      </div>
    </div>
  );
}

export default Header;
