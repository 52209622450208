import React, { useEffect, useRef } from 'react'
import './SideMenu.css'

import { SemiRhombToClose, SemiRhombToOpen } from '../../assets/icons/icons';
import FirstStepDrops from './FirstStepDrops/FirstStepDrops';

import Popup from '../Popup/Popup';
import CtgrMobPopup from '../CtgrMobPopup/CtgrMobPopup';

export default function SideMenu({ categories, popup, handlePopup,
    drop, handleDrop, handleNavLinkClick, setCtgrMobPopup, ctgrMobPopup }) {

    return (
        <>
            <div className='side-menu'>
                <FirstStepDrops
                    categories={categories} drop={drop} isOpen={true}
                    handleDrop={handleDrop} handleNavLinkClick={handleNavLinkClick}
                />
            </div>

            <div className='side-menu_mob'>
                <div className={ctgrMobPopup ? 'side-menu_mob-box_opened' : 'side-menu_mob-box'}>
                    <button type='button' name='firstStepMob' onClick={() => setCtgrMobPopup(true)}
                        className={`side-menu__box-btn_mob-first-step 
                                ${ctgrMobPopup ? 'side-menu__box-btn_mob-first-step-opened' :
                                'side-menu__box-btn_mob-first-step-closed'}`}>
                        Каталог товаров
                        {
                            ctgrMobPopup ? <SemiRhombToClose color='var(--text-on-contrast-color)' />
                                : <SemiRhombToOpen color='var(--text-color' />
                        }
                    </button>

                    <CtgrMobPopup ctgrMobPopup={ctgrMobPopup}
                        setCtgrMobPopup={setCtgrMobPopup}>
                        <FirstStepDrops
                            isOpen={ctgrMobPopup ? true : false}
                            uniqueHeight='fitContnet'
                            popup={popup} handleClosePopup={handlePopup}
                            categories={categories} drop={drop}
                            handleDrop={handleDrop} handleNavLinkClick={handleNavLinkClick}
                        />
                    </CtgrMobPopup>
                </div>
            </div >
        </>
    )
}


