import { MAIN_URL } from "../utils/constants";

class MainApi {
  // constructor({ baseUrl }) {
  //   MAIN_URL = baseUrl;
  // }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === "Signature has expired") {
          console.log("ss");
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  refreshJWT() {
    return fetch(`${MAIN_URL}/users/refresh-jwt`, {
      method: "POST",
      // credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  getConstructor({ shop_id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;

    return fetch(
      `${MAIN_URL}/constructor/get-data?` +
        new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        // credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }


  sendActivityPing({
    shop_id,
    client_uid,
  }) {
    return fetch(`${MAIN_URL}/metrics/set-online`, {
      method: "POST",
      // credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        client_uid,
      }),
    }).then(this._checkResponse);
  }

  sendCartChange({
    shop_id,
    client_uid,
    cart_uid,
    item_id,
    options,
    amount,
    direction,
  }) {
    return fetch(`${MAIN_URL}/metrics/add-to-cart`, {
      method: "POST",
      // credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        client_uid,
        cart_uid,
        item_id,
        options,
        amount,
        direction,
      }),
    }).then(this._checkResponse);
  }

  // getTopProducts({ limit, exclude_id }) {
  //   let params = {};
  //   if (limit) params.limit = limit;
  //   if (exclude_id) params.exclude_id = exclude_id;

  //   return fetch(`${MAIN_URL}/items/get-top?` + new URLSearchParams(params), {
  //     method: "GET",
  //     credentials: "include",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   }).then(this._checkResponse);
  // }

  getExactProduct({ shop_id, _id }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/products/get-by-id?` +
        new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        // credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  getCategories({ limit, last_id, shop_id, _id }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;
    if (shop_id) params.shop_id = shop_id;
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/categories/get-all?` +
        new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        // credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  getCategoriesWithTree({ data, shop_id, limit, last_id }) {
    let params = {};
    if (data) params.data = data;
    if (shop_id) params.shop_id = shop_id;
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;

    return fetch(
      `${MAIN_URL}/categories/get-with-tree?` +
        new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        // credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  // getExactCategory({ key, value }) {
  //   let params = {};
  //   if (key) params.key = key;
  //   if (value) params.value = value;

  //   return fetch(
  //     `${MAIN_URL}/categories/get-exact?` + new URLSearchParams(params),
  //     {
  //       method: "GET",
  //       // credentials: "include",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   ).then(this._checkResponse);
  // }

  getItems({ shop_id, category, limit, last_id }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;
    if (shop_id) params.shop_id = shop_id;
    if (category) params.category = category;

    return fetch(
      `${MAIN_URL}/products/get-all-by-category?` +
        new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        // credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  // getAllSubcategories({ data }) {
  //   let params = {};
  //   if (data) params.data = JSON.stringify(data);

  //   return fetch(
  //     `${MAIN_URL}/sub-categories/get-all?` + new URLSearchParams(params),
  //     {
  //       method: "GET",
  //       // credentials: "include",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   ).then(this._checkResponse);
  // }

  createOrder({
    shop_id,
    email,
    phone,
    data,
    first_name,
    last_name,
    submit_type,
    address,
    // payment_type,
    promo_code,
    return_url,
  }) {
    return fetch(`${MAIN_URL}/orders/create`, {
      method: "POST",
      // credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        email: email,
        phone: phone,
        data: data,
        first_name: first_name,
        last_name: last_name,
        submit_type: submit_type,
        address: address,
        // payment_type: payment_type,
        promo_code: promo_code,
        return_url: return_url ? return_url : null,
      }),
    }).then(this._checkResponse);
  }

  calculateOrder({
    phone,
    email,
    shop_id,
    data,
    first_name,
    last_name,
    submit_type,
    address,
    promo_code,
    return_url,
  }) {
    return fetch(`${MAIN_URL}/orders/calculate-order`, {
      method: "POST",
      // credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        shop_id,
        email: email,
        phone: phone,
        data: data,
        first_name: first_name,
        last_name: last_name,
        submit_type: submit_type,
        address: address,
        promo_code: promo_code,
        return_url: return_url ? return_url : null,
      }),
    }).then(this._checkResponse);
  }

  checkPromo({ shop_id, code }) {
    let params = {};
    if (shop_id) params.shop_id = shop_id;
    if (code) params.code = code;

    return fetch(
      `${MAIN_URL}/codes/check?` +
        new URLSearchParams({ data: JSON.stringify(params) }),
      {
        method: "GET",
        // credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  // subscribeToNewsletter({ email }) {
  //   return fetch(`${MAIN_URL}/landing/write`, {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       email: email,
  //     }),
  //   }).then(this._checkResponse);
  // }

  getManyItems({ data }) {
    let params = {};
    if (data) params.data = JSON.stringify(data);

    return fetch(
      `${MAIN_URL}/products/get-many?` + new URLSearchParams(params),
      {
        method: "GET",
        // credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }
}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
