import { Link } from "react-router-dom";
import { CartIcon } from "../../assets/icons/icons";
import "./Card.css";
import { CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT } from "../../assets/utils/constants";
import { buildCategoryPath, parsePrice } from "../../assets/utils/utils";

function Card({
  product,

  popular,
  numberCard,
}) {


  const reversedArray = [...product.categories[0].parents].reverse();

  return (
    <Link
      to={`/${CATALOG_MAIN_LINK}/${buildCategoryPath(reversedArray, product.categories[0].parents.length - 1)}/${CATALOG_ITEM_LINK}/${product._id}`}
      className={`product-card ${popular === true && numberCard === 0 ? "product-card_type_big" : ""
        } ${product.discount ? "product-card_type_discount" : ""}`}
    >
      {/* <Link
        className="product-card__link"
        
      /> */}
      <p
        style={{ gridArea: `title${numberCard}` }}
        className={`product-card__title`}
      >
        {product.name && product.name.length > 0 ? product.name : ` `}
      </p>
      <img
        style={{ gridArea: `img${numberCard}` }}
        className="product-card__img"
        alt=""
        src={product.files && product.files.length > 0 ? product.files[0].urls.orig : IMG_PLACEHOLDER_PRODUCT}
      />
      <button
        style={{ gridArea: `button${numberCard}` }}
        className={`product-card__button ${product.discount ? "" : ""}`}
        type="button"
      >
        <div
          className={`product-card__button__price ${product.discount ? "product-card__button__price_discount" : ""
            }`}
        >
          <p className="product-card__button__price__text">
            {parsePrice(Number(product.price_data.discount) > 0 ? product.price_data.price : Number(product.price_data.price) - (Number(product.price_data.price) / 100 * Number(product.price_data.discount)), 'RUB')}
          </p>
        </div>
        <div
          className={`product-card__button__cart ${product.price_data.discount ? "product-card__button__cart_discount" : ""
            }`}
        >
          {product.price_data.discount ? (
            <div className="cart_discount">
              <p className="cart_discount__text">−{product.price_data.discount}%</p>
            </div>
          ) : (
            CartIcon({
              mainClassName: "cart__logo",
              fillClassName: "cart__logo-fill",
            })
          )}
        </div>
      </button>
    </Link>
  );
}

export default Card;
