import { useContext, useState } from "react";
import Input from "../../Input/Input";
import "./Order.css";
import mainApi from "../../../assets/api/MainApi";
import PromoCheckPopup from "../PromoCheckPopup/PromoCheckPopup";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import { ConfigContext } from "../../../assets/contexts/configContext";

// import { BASKET } from '../../../assets/utils/constants';

export const Order = ({
  cart,
  handleNextStep,
  step,
  promo,
  setPromo,
  isPersonalValid,
  isPreloaderVisible,
  total,
}) => {
  const {
    PRICE,
    ITEMS_PRICE,
    ITEMS_DISCOUNT,
    ITEMS_COUNT,
    PRODUCTS,
    PROMO_DISCOUNT,
    DELIVERY_PRICE,
  } = total;
  // const total_price =

  const [popups, setPopups] = useState({
    promo: false,
  });

  function handleChange(e) {
    const input = e.target;
    let value = input.value;

    setPromo({
      isValid: false,
      value: value,
    });
  }

  const config = useContext(ConfigContext)
  const { initialConstants } = config
  const { SHOP_ID_FOR_API, } = initialConstants

  function handleUsePromo() {
    mainApi
      .checkPromo({
        shop_id: SHOP_ID_FOR_API,
        code: promo.value,
      })
      .then((res) => {
        setPromo((prevValue) => ({
          ...prevValue,
          isValid: true,
          discount: res.discount,
        }));
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPopups((prevValue) => ({
          ...prevValue,
          promo: true,
        }));
      });
  }

  return (
    <div className="order">
      <h3 className="order__title">Ваш заказ</h3>
      <div className="order__params">
        <div className="order__param">
          <h4 className="order__param-name">Товаров</h4>
          <div className="order__param-line"></div>
          <h4 className="order__param-value">{ITEMS_COUNT}</h4>
        </div>
        <div className="order__param">
          <h4 className="order__param-name">На сумму</h4>
          <div className="order__param-line"></div>
          <h4 className="order__param-value">
            {Number(ITEMS_PRICE).toLocaleString()} ₽
          </h4>
        </div>
        {ITEMS_DISCOUNT > 0 ? (
          <div className="order__param">
            <h4 className="order__param-name">Скидка</h4>
            <div className="order__param-line"></div>
            <h4 className="order__param-value">
              {Number(ITEMS_DISCOUNT).toLocaleString()} ₽
            </h4>
          </div>
        ) : null}

        {promo.isValid && PROMO_DISCOUNT > 0 ? (
          <div className="order__param">
            <h4 className="order__param-name">
              Промо-код (-{promo.discount}%)
            </h4>
            <div className="order__param-line"></div>
            <h4 className="order__param-value">
              {Number(PROMO_DISCOUNT).toLocaleString()} ₽
            </h4>
          </div>
        ) : null}
        {/* <div className="order__param">
                    <span className='order__param-name'>Доставка</span>
                    <div className="order__param-line"></div>
                    <span className='order__param-value'>{Number(DELIVERY_PRICE).toLocaleString()} ₽</span>
                </div> */}

        <div className="order__param order__param_type_total">
          <h4 className="order__param-name">Сумма к оплате</h4>
          <div className="order__param-line"></div>
          <h4 className="order__param-value">
            {Number(PRICE).toLocaleString()} ₽
          </h4>
        </div>
      </div>
      <p className="order__hint"></p>

      <div className="order__controls">
        <div className="order__promo">
          <Input
            label={"Промо-код"}
            value={promo.value}
            handleChange={handleChange}
            name={"promo_code"}
            // error={formValid.email}
          ></Input>
          <button
            className={`order__promo-button ${
              promo.value ? "" : "order__promo-button_inactive"
            }`}
            onClick={handleUsePromo}
          >
            <p className="order__promo-button-text">Применить</p>
          </button>
        </div>
        {/* <button className='order__btn order__btn_up' type='Button'>Применить промо-код</button> */}
        <button
          className={`order__btn-submit 
                ${
                  step === 0 && (!cart || cart.length === 0)
                    ? "order__btn-submit_inactive"
                    : ""
                }
                ${
                  step === 1 && !isPersonalValid
                    ? "order__btn-submit_inactive"
                    : ""
                }
                ${isPreloaderVisible ? "order__btn-submit_inactive" : ""}
                `}
          type="button"
          onClick={handleNextStep}
        >
          {isPreloaderVisible ? (
            <MiniPreloader isLinkColor={true} />
          ) : step === 1 ? (
            <h3 className="order__btn-submit-text">Перейти к оплате</h3>
          ) : (
            <h3 className="order__btn-submit-text">Оформить заказ</h3>
          )}
        </button>
      </div>

      <PromoCheckPopup
        isOpen={popups.promo}
        promo={promo}
        popupName={"promo"}
        setOpen={setPopups}
      />
    </div>
  );
};
