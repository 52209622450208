import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Autoplay } from 'swiper';
import "./BanerSlide.css";

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { BANER_SLIDE } from "../../assets/utils/fakeData";
import { IMG_PLACEHOLDER_PROMO } from '../../assets/utils/constants';

function BanerSlide({ item }) {
console.log(item)
    return (
        <div className="main__banner">
            <div className="bnr-slide">
                <Swiper
                    spaceBetween={24}
                    slidesPerView={"auto"}
                    mousewheel={{
                        forceToAxis: true,
                    }}
                    modules={[Mousewheel, Autoplay]}
                    preventInteractionOnTransition={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                >
                    {item.data && item.data.length > 0 ?
                        item.data.map((slide, i) => {
                            return (
                                <SwiperSlide className="bnr-slide__slide" key={i}>
                                    <img src={`${slide.image ? slide.image.urls.orig : IMG_PLACEHOLDER_PROMO}`} className="bnr-slide__img" alt=""></img>
                                </SwiperSlide>
                            )
                        })
                        : null}
                </Swiper>
            </div>
        </div>
    );
}

export default BanerSlide;