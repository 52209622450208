import "./CardsGrid.css";
import Card from "../Card/Card";

function CardsGrid({
  handleAddToCart,
  setCart,
  cart,
  handleRemoveFromCart,
  item,
  subcategory
}) {

  return (
    <>


      <div className={`${item.style ? item.style : 'main__grid-hits'} ${item.data[0].style ? item.data[0].style : ''}`}>

      
        {item && item.data[0] && item.data[0].products ?

          <div className="cards-grid">
            {item.data[0].title ? <p className="cards-grid__title">{item.data[0].title}</p> : ""}
            <div className="cards-grid__list">
              {item.data[0].products?.map((item, i) => {
                return (
                  <Card
                    numberCard={i}
                    // popular={item.popular}
                    product={item}
                    key={`${item.title ? item.title : "list"}-${i}`}
                  />
                );
              })}</div>
          </div>
          : null}
      </div>
    </>
  );
}

export default CardsGrid;
