import React from 'react'
import './Popup.css'

export default function Popup({ isOpened, name, handleClose, children }) {
    return (
        <div className={isOpened ? 'popup popup_active' : 'popup'} onClick={() => handleClose(name)}>
            <div className={isOpened ? 'popup__box popup__box_active' : 'popup__box'}
                onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}