import BanerSlide from "../BanerSlide/BanerSlide";
import SideMenu from "../SideMenu/SideMenu";
import "./Main.css";
import React, { useContext } from "react";
import { ConfigContext } from '../../assets/contexts/configContext';
import SearchBar from "../Search/SearchBar/SearchBar";
import { SIDE_MENU_CATEGORIES } from "../../assets/utils/fakeData";
import CardsGrid from "../CardsGrid/CardsGrid";
import Promo from "../Promo/Promo";


function Main({
  searchKey,
  setSearchKey,
  popup,
  handlePopup,
  drop,
  handleDrop,
  handleNavLinkClick,
  setCtgrMobPopup,
  ctgrMobPopup,
}) {
  const MAIN_LAYOUT_TYPES = {
    SIDE_MENU: 'side-menu',
    PROMO_BANNER_SCROLL: 'promo-banner-scroll',
    SEARCH_BAR: 'search-bar',
    PROMO_TEXT_IMAGE: 'showcase-line-2-with-image',
    SHOWCASE_LINE_1: 'showcase-line-1',
    SHOWCASE_LINE_2: 'showcase-line-2',
    // SHOWCASE_LINE_3: 'showcase-line-3',
    // SHOWCASE_SCROLL: 'showcase-scroll',
    // SHOWCASE_LINE_1_WITH_IMAGE: 'showcase-line-1-with-image',
    // SHOWCASE_LINE_2_WITH_IMAGE: 'showcase-line-2-with-image',
    // SHOWCASE_LINE_3_WITH_IMAGE: 'showcase-line-3-with-image',
    // SHOWCASE_LINE_1_WITH_HERO: 'showcase-line-1-with-hero',
    // SHOWCASE_LINE_2_WITH_HERO: 'showcase-line-2-with-hero',
    // SHOWCASE_LINE_3_WITH_HERO: 'showcase-line-3-with-hero',
  }

  const MAIN_LAYOUT_COMPONENTS = {
    [MAIN_LAYOUT_TYPES.SIDE_MENU]: SideMenu,
    [MAIN_LAYOUT_TYPES.PROMO_BANNER_SCROLL]: BanerSlide,
    [MAIN_LAYOUT_TYPES.SEARCH_BAR]: SearchBar,
    [MAIN_LAYOUT_TYPES.PROMO_TEXT_IMAGE]: Promo,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1]: CardsGrid,
    [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2]: CardsGrid,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3]: ShowcaseLine3,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_SCROLL]: ShowcaseScroll,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1_WITH_IMAGE]: ShowcaseLineImage1,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2_WITH_IMAGE]: ShowcaseLineImage2,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3_WITH_IMAGE]: ShowcaseLineImage3,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_1_WITH_HERO]: ShowcaseLineHero1,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_2_WITH_HERO]: ShowcaseLineHero2,
    // [MAIN_LAYOUT_TYPES.SHOWCASE_LINE_3_WITH_HERO]: ShowcaseLineHero3,
  }

  const config = useContext(ConfigContext)
  const { mainLayout, initialConstants } = config
  const { FIRST_LEVEL_CATEGORIES } = initialConstants
  console.log(mainLayout, config)

  return (
    <main className="main">
      {/* <div className="main__menu">
        <div className="main__menu-box">
          <SideMenu
            {...{
              categories,
              setCtgrMobPopup,
              ctgrMobPopup,
              popup,
              handlePopup,
              drop,
              handleDrop,
              handleNavLinkClick,
            }}
          />
        </div>
      </div> */}

      <React.Fragment key={`main-layout-item_${'menu'}-${1}`} >
        {React.createElement(MAIN_LAYOUT_COMPONENTS['side-menu'], { categories: FIRST_LEVEL_CATEGORIES, setSearchKey, searchKey, setCtgrMobPopup, ctgrMobPopup, popup, handlePopup, drop, handleDrop, handleNavLinkClick })}
      </React.Fragment>
      <div className="main__box">
        {mainLayout && Array.isArray(mainLayout) && mainLayout.length > 0 ?
          mainLayout.map((item, i) => (
            <React.Fragment key={`main-layout-item_${item._id}-${i}`} >
              {React.createElement(MAIN_LAYOUT_COMPONENTS[item.type], { key: i, categories: FIRST_LEVEL_CATEGORIES, item, setSearchKey, searchKey, setCtgrMobPopup, ctgrMobPopup, popup, handlePopup, drop, handleDrop, handleNavLinkClick })}
            </React.Fragment>)
          ) : null}
      </div>


    </main>
  );
}

export default Main;
