import CardsGrid from "../CardsGrid/CardsGrid";
import ButtonAll from "../ButtonAll/ButtonAll";
import bnr from "../../assets/images/staticbnr.webp";
import "./Promo.css";
import { CARDS_PROMO } from "../../assets/utils/fakeData";
import { IMG_PLACEHOLDER_PROMO } from "../../assets/utils/constants";

function Promo({
  handleAddToCart,
  setCart,
  cart,
  handleRemoveFromCart,
  item
}) {

  const { title, text, link, btn_text, image, products } = item.data[0]

  return (
    <div className="main__promo">
      <div className="promo">
        <img className="promo__bnr" src={`${image ? image.urls.orig : IMG_PLACEHOLDER_PROMO}`} alt="" />
        <p className="promo__title">{title}</p>
        <div className="promo__label">
          <p className="promo__label-text">{text}</p>
        </div>

        <CardsGrid
          item={item}
        />

        {btn_text && link ?
          <div className="promo__link">
            <ButtonAll
              title={btn_text}
              // title="Смотреть все"
              link={link}
            />
          </div>
          : null}

      </div>
    </div>
  );
}

export default Promo;
