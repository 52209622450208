import React, { useContext, useState } from "react";
import Drop from "../../Drop/Drop";
import { SemiRhombToClose, SemiRhombToOpen } from "../../../assets/icons/icons";
import { motion } from "framer-motion";
import '../SideMenu.css'
import '../../Popup/Popup.css'
import { Link, useNavigate } from "react-router-dom";
import mainApi from "../../../assets/api/MainApi";
import { ConfigContext } from "../../../assets/contexts/configContext";
import { CATALOG_MAIN_LINK } from "../../../assets/utils/constants";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

export default function FirstStepDrops({

    categories, handleNavLinkClick,
    isOpen, popup, handleClosePopup
}) {
    const config = useContext(ConfigContext)
    const { initialConstants } = config
    const { SHOP_ID_FOR_API, } = initialConstants

    const navigate = useNavigate();

    const [selectedCategory, setSelectCategory] = useState(undefined);
    const [isPreloaderVisible, setPreloaderVisible] = useState(false);
    const [subcategories, setSubcategories] = useState(undefined);

    function handleSelectCategory(item) {
        if (selectedCategory && selectedCategory._id === item._id) return;
        setSelectCategory(prevValue => ({
            ...prevValue,
            subcategories_open: false,
        }))
        if (item.is_final) {
            navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
            //   closeDropdown()
            return
        }
        setSelectCategory(item);
        setPreloaderVisible(true);

        mainApi
            .getCategories({
                limit: 25,
                shop_id: SHOP_ID_FOR_API,
                _id: item._id,
            })
            .then((res) => {
                setSubcategories(res.data);
                setSelectCategory(prevValue => ({
                    ...prevValue,
                    subcategories: res.data,
                }))
                setTimeout(() => {
                    setSelectCategory(prevValue => ({
                        ...prevValue,
                        subcategories_open: true,
                    }))
                }, 100);

                if (res.data.length === 0) {
                    navigate(`/${CATALOG_MAIN_LINK}/${item.translit_name}`);
                    //   closeDropdown()

                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setPreloaderVisible(false);
            });
    }


    return (
        <motion.div
            initial={{
                opacity: 0,
                visibility: "hidden",
            }}
            animate={{
                opacity: isOpen ? 1 : 0,
                visibility: isOpen ? "visible" : "hidden",
            }}
            transition={{ duration: 0.2 }}
            className={popup && popup.categories ? 'side-menu__box-with-popup' : 'side-menu__box'}
        >

            {Array.isArray(categories) ? categories.map((item, i) => (
                <div className="side-menu__content">
                    <button type='button' name='house' onClick={() => handleSelectCategory(item)}
                        className='side-menu__box-btn' style={{ color: 'var(--accent-color' }}>
                        {item.name}
                        {/* {
                            drop.school ? <SemiRhombToClose color='var(--accent-color' />
                                : <SemiRhombToOpen color='var(--accent-color' />
                        } */}
                    </button>
                    {selectedCategory && selectedCategory.translit_name === item.translit_name ?
                        isPreloaderVisible ? (
                            <div className="catalog-drop__preloader">
                                <MiniPreloader />
                            </div>
                        ) :
                            selectedCategory.subcategories && selectedCategory.subcategories.length > 0 ?
                                <Drop
                                    items={selectedCategory.subcategories}
                                    selectedCategory={selectedCategory}
                                    isOpen={selectedCategory.subcategories_open}
                                />
                                :
                                null
                        : null}
                </div>

            )) : null}

            {
                popup && popup.categories ?
                    <button className='popup__box-close-btn' type='button'
                        onClick={() => handleClosePopup('categories')}>Закрыть</button>
                    : null
            }
        </motion.div>
    )
}
