import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../../assets/icons/icons";
import "./SearchBar.css";

function SearchBar({ searchValue = "", searchKey, setSearchKey }) {
  const navigate = useNavigate();

  function handleKeyChange(evt) {
    setSearchKey(evt.target.value);
  }

  function handleSearchSubmit(evt) {
    evt.preventDefault();
    console.log(searchKey);
    navigate(`/search?value=${searchKey}`);
    setSearchKey("");
  }

  useEffect(() => {
    setSearchKey(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <>
    <div></div>
    <div className="main__search">
      <div className="search-bar">
        <form className="search-bar__form" onSubmit={handleSearchSubmit}>
          <input
            className="search-bar__input"
            id="globalSearch"
            name="search"
            type="text"
            placeholder="Поиск по каталогу"
            value={searchKey || ""}
            onChange={handleKeyChange}
          />
          <button
            className="search-bar__submit-btn"
            type="submit"
            aria-label="Confirm search"
          >
            {SearchIcon({
              mainClassName: "search-bar__search-icon",
              fillClassName: "search-bar__search-icon-fill",
            })}
          </button>
        </form>
      </div>
    </div>
    </>
  );
}

export default SearchBar;
