import { Link } from "react-router-dom";
import { CATALOG_MAIN_LINK, IMG_PLACEHOLDER_PRODUCT, MAIN_URL } from "../../../assets/utils/constants";
import "./CategoryCard.css";
import { useContext } from "react";
import { ConfigContext } from "../../../assets/contexts/configContext";
import { buildCategoryPath } from "../../../assets/utils/utils";

function CategoryCard({ category, levels }) {
  const config = useContext(ConfigContext);
  const { cardTokens } = config;

  const { image_radius } = cardTokens;

  return (
    <Link
      className="category-card"
      to={`${buildCategoryPath(levels, levels.length - 1, false)}/${
        category.translit_name
      }`}
      key={``}
    >
      <img
        className="category-card__img"
        src={category.file ? category.file.urls.orig : IMG_PLACEHOLDER_PRODUCT}
        alt={category.name}
      />
      <h3 className="category-card__name">
        {category.name} ({category.published_count})
      </h3>
      {/* <h3 className="category-card__name">{item.name}</h3>
            <div className="category-card__gradient"></div>
            <img className="category-card__img" src={`${MAIN_URL}/get-file/${item.photo}`} alt={item.name} key={item._id} /> */}
    </Link>
  );
}

export default CategoryCard;
