import { useRoutes, useLocation } from 'react-router-dom';
import Cart from '../Cart/Cart';
import Main from '../Main/Main';
import ProductPage from '../ProductPage/ProductPage';
import { CART_MAIN_LINK, CATALOG_ITEM_LINK, CATALOG_MAIN_LINK, PRIVACY_POLICY_LINK, SEARCH_LINK, USER_AGREEMENT_LINK } from '../../assets/utils/constants';
import Category from '../Category/Category';
import { SIDE_MENU_CATEGORIES } from '../../assets/utils/fakeData';
import SubCategory from '../SubCategory/SubCategory';
import Search from '../Search/Search';
import Catalog from '../Catalog/Catalog';
import StaticDocs from '../StaticDocs/StaticDocs';

import { USER_AGREEMENT_TEXT } from '../../assets/utils/legal_docs/user_agreement'
import { PRIVACY_POLICY_TEXT } from '../../assets/utils/legal_docs/privacy_policy'

const Router = ({
    cart,
    handleAddToCart,
    handleRemoveFromCart,
    setCart,
    popup,
    handlePopup,
    drop,
    handleDrop,
    handleNavLinkClick,
    setCtgrMobPopup,
    ctgrMobPopup,
    searchKey,
    setSearchKey,
    setDrop,
    setPopup,
    secondStepBtnTxt,
    handleChangeCheckbox,
    checkboxes,
    setCheckboxes,
    color,
    setColor,
    handleSelect,
}) => {
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(Boolean);

    // Check if the last segment is a product ID
    const isProductPage = pathSegments.length > 2 && pathSegments[pathSegments.length - 2] === CATALOG_ITEM_LINK;

    // Common props for Cart and ProductPage
    const commonProps = {
        cart,
        handleAddToCart,
        handleRemoveFromCart,
        setCart,

        popup,
        handlePopup,
        drop,
        handleDrop,
        handleNavLinkClick,
        setCtgrMobPopup,
        ctgrMobPopup,
    };

    const commonProductProps = {
        handleAddToCart,
        handleRemoveFromCart,
        cart
    };

    // Routes configuration based on URL segments
    const routes = [
        { path: `/`, element: <Main setCtgrMobPopup={setCtgrMobPopup} ctgrMobPopup={ctgrMobPopup} /> },
        { path: `/${CART_MAIN_LINK}`, element: <Cart /> },
        { path: `/${USER_AGREEMENT_LINK}`, element: <StaticDocs text={USER_AGREEMENT_TEXT} /> },
        { path: `/${PRIVACY_POLICY_LINK}`, element: <StaticDocs text={PRIVACY_POLICY_TEXT} /> },
        {
            path: `/${CATALOG_MAIN_LINK}/*`,
            element: isProductPage
                ? <ProductPage item_id={pathSegments[pathSegments.length - 1]} levels={pathSegments.slice(1, -2)} />
                : <Catalog levels={pathSegments.slice(1)} />
        },
    ];

    const element = useRoutes(routes);
    return element;
};


export default Router;